<template>
  <Dialog
    :dialog-title="dialogTitle"
    :is-show-dialog="formDialog"
    label-width="100px"
    @closeDialog="closeDialog"
    @submitForm="submitFormInfo">
    <div style="width: 80%;">
      <p style="padding-bottom: 14px;">{{ hintText }}</p>
      <el-input
        v-model="deleteRemark"
        :rows="4"
        type="textarea"
        maxlength="50"
        show-word-limit
        placeholder="请说明原因(必填)" />
    </div>
  </Dialog>
</template>

<script>
import Dialog from '../../../components/Dialog';
import { regDelete } from '../../../api/QuarantineRegistration/quarantineRegistrationpop';
import { auditRelieveIsolateApi } from '../../../api/Taskmanagement/upcomingTasks';

export default {
  name: "DelResidentDialog",
  components: { Dialog },
  data() {
    return {
      formDialog: false,
      deleteRemark: "",
      hintText: "",
      id: ""
    }
  },

  props: {
    // 弹窗标题  此组件在多个页面使用，此字段用来区分是哪个页面使用的
    dialogTitle: {
      type: String,
      default: ""
    }
  },

  methods: {
    show(id) {
      this.formDialog = true;
      this.deleteRemark = "";
      this.id = id;

      switch (this.dialogTitle) {
        case '删除信息':
          this.hintText = "确定删除当前登记用户信息吗？"
          break;
        case '拒绝提示':
          this.hintText = "确定拒绝当前解除隔离申请吗？"
          break;
      }
    },

    // 删除信息 - 关闭弹窗
    closeDialog() {
      this.formDialog = false;
    },

    // 删除信息 - 提交
    submitFormInfo() {
      console.log(2222222,this.dialogTitle);
      if (!this.deleteRemark) return this.$message.warning("请输入删除说明");

      switch (this.dialogTitle) {
        case '删除信息':
          this.delRegInfo()
          break;
        case '拒绝提示':
          this.rejectQuarantine();
          break;
      }
    },

    // 删除居民信息
    delRegInfo() {
      let params = {
        id: this.id,
        deleteRemark: this.deleteRemark
      };
      regDelete({ params }).then(res => {
        let { code, msg } = res.data;
        if (code == 200) {
          this.$message.success(msg)
          this.formDialog = false;
          this.$parent.getListInfo()
        } else {
          this.$message.error(msg)
        }
      })
    },

    // 解除隔离审核 - 拒绝审核
    rejectQuarantine() {
      let params = {
        id: this.id,
        type: 0,
        applyRefuseRemark: this.deleteRemark
      }
      auditRelieveIsolateApi(params).then(res => {
        let { code, msg } = res.data;
        if (code == 200) {
          this.$message.success(msg);
          this.formDialog = false;
          this.$parent.applyList()
        } else {
          this.$message.error(msg);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
